import * as pbi from "powerbi-client";
import {
  FunctionComponent,
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import useFetch, { isUnhandled } from "../../../hooks/useFetch";
import { GoogleAnalyticsActions } from "../../../hooks/useGoogleAnalytics";
import Loading from "../../ui/Loading";
import { CancelToken } from "../../utils/Util";

export interface Props extends HTMLAttributes<HTMLDivElement> {
  link: string;
  onLoadDashboard?: (event: { link: string }) => void;
  isMobile?: boolean;
}

export interface State {
  hasError: boolean;
  loading: boolean;
}

interface IEvents {
  detail: {
    id?: string;
    dataPoints?: any;
    type?: any;
  };
}

const defaultState = {
  hasError: false,
  loading: false,
};

enum HyperlinkClickBehavior {
  Navigate,
  NavigateAndRaiseEvent,
  RaiseEvent,
}

const EmbeddedDashboard: FunctionComponent<Props> = (props) => {
  const [{ hasError, loading }, setState] = useState<State>(defaultState);
  const fetch = useFetch();
  const { link, onLoadDashboard, isMobile, ...divProps } = props;
  const dashboardDiv = useRef<HTMLDivElement>(null);
  const cancelAll = useRef<CancelToken>(new CancelToken());
  const ref = useRef<Pick<Props, "onLoadDashboard">>({});

  const fetchDashboard = useCallback(
    async (token: CancelToken) => {
      setState((prev) => ({ ...prev, loading: true }));

      try {
        const { data: dashboardEmbedData } = await fetch({
          method: "GET",
          url: "/api/v1/fgv/dashboard/" + link,
          cancelToken: token,
          options: { auth: false },
        });

        let config = {
          type: "report",
          tokenType: pbi.models.TokenType.Embed,
          accessToken: dashboardEmbedData.embedToken,
          embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${dashboardEmbedData.relatoryId}&groupId=${dashboardEmbedData.groupRelatory}&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUJSQVpJTC1TT1VUSC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOmZhbHNlfX0%3d`,
          id: dashboardEmbedData.relatoryId,
          permissions: pbi.models.Permissions.All,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            hyperlinkClickBehavior: HyperlinkClickBehavior.Navigate,
            ...(isMobile
              ? { layoutType: pbi.models.LayoutType.MobilePortrait }
              : {}),
          },
        };

        if (token.isCancelled) return;

        const dashboardDivCurrent = dashboardDiv.current;

        if (!dashboardDivCurrent) return;

        const powerbi = new pbi.service.Service(
          pbi.factories.hpmFactory,
          pbi.factories.wpmpFactory,
          pbi.factories.routerFactory
        );
        let report = powerbi.embed(dashboardDivCurrent, config);

        report.on("dataSelected", (event: IEvents) => {
          try {
            event?.detail?.dataPoints[0].identity[0].target.column ===
              "Categoria" &&
              GoogleAnalyticsActions.event(
                "categoria_dashboard",
                "",
                event.detail.dataPoints[0].identity[0].equals
              );
          } catch (error) {}
        });

        report.on("buttonClicked", async (event: IEvents) => {
          try {
            event?.detail?.id === "e4a916fcccb02bfdd37f" &&
              GoogleAnalyticsActions.event("botao_avancar", "", "ㅤ");

            event?.detail?.id === "d0fe0ea8f01926b330f6" &&
              GoogleAnalyticsActions.event("inf_outras_cidades", "", "ㅤ");

            if (event?.detail?.id === "23a3293cc4f7db3b8bbf") {
              GoogleAnalyticsActions.event(
                "iniciou_cadastro",
                "",
                "click_dash"
              );
            } else {
              GoogleAnalyticsActions.event(
                "carrinho_compras",
                "",
                "click_dash"
              );
            }
          } catch (error) {}
        });

        token.subscribe(() => powerbi.reset(dashboardDivCurrent));

        const { onLoadDashboard } = ref.current;

        if (onLoadDashboard) onLoadDashboard({ link });
        setState((prev) => ({ ...prev, hasError: false, loading: false }));
      } catch (e) {
        if (isUnhandled(e))
          setState((prev) => ({ ...prev, hasError: true, loading: false }));
      }
    },
    [fetch, link, setState, ref]
  );

  // Copy props to ref
  useEffect(() => {
    ref.current.onLoadDashboard = onLoadDashboard;
  }, [onLoadDashboard]);

  // Syncs shown dashboard with the link prop
  useEffect(() => {
    const token = new CancelToken();

    fetchDashboard(token);

    return () => token.cancel();
  }, [fetchDashboard]);

  // When leaving the component, cancel everything that can be happening
  useEffect(() => () => cancelAll.current.cancel(), [cancelAll]);

  return (
    <div {...divProps}>
      {hasError ? (
        <div className="hd-embedded-dash__center">
          <div>Erro!</div>
          <div>Ocorreu um erro ao carregar o relatório</div>
          <div>:(</div>
          <br />
          <button
            className="hd-embedded-dash__btn"
            disabled={loading}
            type="button"
            onClick={() => fetchDashboard(cancelAll.current)}
          >
            {loading ? "Carregando..." : "Atualizar"}
          </button>
        </div>
      ) : loading ? (
        <Loading type="contain" />
      ) : null}
      <div id="dashboard" className="hd-embedded__target" ref={dashboardDiv} />
    </div>
  );
};

export default EmbeddedDashboard;
